<script setup lang="ts">
	import PageHeader from "@/components/Navigation/PageHeader.vue";
	import { ref } from 'vue';
	import {
		useAuditTrailsActiveConnections
	} from "@/composables/audit-trails-api/audit-trails";
	import {
		useAuditTrailsHttp
	} from "@/composables/audit-trails-api/http-audit-trails";
	import { AuditTrailsType } from "/src/composables/api-models";
	import ListConnection from "/src/components/RemoteAccess/ListConnection.vue";
	import { AuditTrailsPageType } from "@/composables/audit-trails-api";

	// http request to get active connections
	const auditTrailsHttp = useAuditTrailsHttp();
	const response = useAuditTrailsActiveConnections(auditTrailsHttp);
	const type = AuditTrailsPageType.Active;
		
</script>

<template>
	<div class="box-border flex min-h-0 flex-col gap-4 pl-5">
		<PageHeader title="Active ECS Tasks" />	
		<ListConnection :tasks="response.data.value as AuditTrailsType[]" :type="type" :isLoading="response.isLoading.value"/>		
	
	</div>

</template>



