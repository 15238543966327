<script setup lang="ts">
	import PageHeader from "@/components/Navigation/PageHeader.vue";
	import { computed, ref } from "vue";
	import { useRouter, useRoute} from "vue-router";
	import { defineProps } from "vue";
	import { useAuditTrailsHttp } from "/src/composables/audit-trails-api";
	import { AuditTrailsPageType, useAuditTrailsByMachine } from "/src/composables/audit-trails-api/audit-trails";
	import { AuditTrailsType } from "/src/composables/api-models";
	import ListConnection from "/src/components/RemoteAccess/ListConnection.vue";

	// get the project id from the route
	const props = defineProps<{
		machineId: string;
		machineName: string;
	}>();
	const route = useRoute();
	
	
	// http request to get active connections
	const auditTrailsHttp = useAuditTrailsHttp();
	const machineIdRef = ref(props.machineId);
	const response = useAuditTrailsByMachine(auditTrailsHttp, machineIdRef);
	const type = AuditTrailsPageType.History;


</script>
<template>
	<PageHeader :title="`Historical ECS Tasks: ${machineName}`" :back="true" />

	<ListConnection :tasks="response.data.value as AuditTrailsType[]" :type="type" :isLoading="response.isLoading.value" />

</template>
<style scoped></style>
