/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 *
 *
 * 0 = None
 *
 * 1 = Active
 *
 * 2 = Revoked
 *
 * 3 = Inactive
 * @format int32
 */
export enum MachineCertificateStatus {
	None = 0,
	Active = 1,
	Revoked = 2,
	Inactive = 3,
  }
  
  export interface MachineCredentialCertificateDto {
	/** @format uuid */
	ProjectId?: string;
	/** @format uuid */
	MachineId?: string;
	MachineName?: string | null;
	ZipFilePath?: string | null;
	DebianFilePath?: string | null;
	/**
	 *
	 *
	 * 0 = None
	 *
	 * 1 = Active
	 *
	 * 2 = Revoked
	 *
	 * 3 = Inactive
	 */
	CertificateStatus?: MachineCertificateStatus;
	/** @format date-time */
	DeployedTimeUTC?: string;
	/** @format date-time */
	UpdateTimeUTC?: string;
	DebianFilePreSignedUrl?: string | null;
	ZipFilePreSignedUrl?: string | null;
  }
  
  export interface MachineCredentialDto {
	/** @format uuid */
	MachineId?: string;
	MachineName?: string | null;
	/** @format uuid */
	ProjectId?: string;
	/**
	 *
	 *
	 * 0 = None
	 *
	 * 1 = Active
	 *
	 * 2 = Revoked
	 *
	 * 3 = Inactive
	 */
	CertificateStatus?: MachineCertificateStatus;
	ZipFilePath?: string | null;
	DebianFilePath?: string | null;
	DebianFilePreSignedUrl?: string | null;
	ZipFilePreSignedUrl?: string | null;
  }
  
  export interface ProblemDetails {
	type?: string | null;
	title?: string | null;
	/** @format int32 */
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
	[key: string]: any;
  }
  
  export type QueryParamsType = Record<string | number, any>;
  export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;
  
  export interface FullRequestParams extends Omit<RequestInit, "body"> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseFormat;
	/** request body */
	body?: unknown;
	/** base url */
	baseUrl?: string;
	/** request cancellation token */
	cancelToken?: CancelToken;
  }
  
  export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;
  
  export interface ApiConfig<SecurityDataType = unknown> {
	baseUrl?: string;
	baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
	securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
	customFetch?: typeof fetch;
  }
  
  export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
	data: D;
	error: E;
  }
  
  type CancelToken = Symbol | string | number;
  
  export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
  }
  
  export class HttpClient<SecurityDataType = unknown> {
	public baseUrl: string = "";
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private abortControllers = new Map<CancelToken, AbortController>();
	private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);
  
	private baseApiParams: RequestParams = {
	  credentials: "same-origin",
	  headers: {},
	  redirect: "follow",
	  referrerPolicy: "no-referrer",
	};
  
	constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
	  Object.assign(this, apiConfig);
	}
  
	public setSecurityData = (data: SecurityDataType | null) => {
	  this.securityData = data;
	};
  
	protected encodeQueryParam(key: string, value: any) {
	  const encodedKey = encodeURIComponent(key);
	  return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
	}
  
	protected addQueryParam(query: QueryParamsType, key: string) {
	  return this.encodeQueryParam(key, query[key]);
	}
  
	protected addArrayQueryParam(query: QueryParamsType, key: string) {
	  const value = query[key];
	  return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
	}
  
	protected toQueryString(rawQuery?: QueryParamsType): string {
	  const query = rawQuery || {};
	  const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
	  return keys
		.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
		.join("&");
	}
  
	protected addQueryParams(rawQuery?: QueryParamsType): string {
	  const queryString = this.toQueryString(rawQuery);
	  return queryString ? `?${queryString}` : "";
	}
  
	private contentFormatters: Record<ContentType, (input: any) => any> = {
	  [ContentType.Json]: (input: any) =>
		input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
	  [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
	  [ContentType.FormData]: (input: any) =>
		Object.keys(input || {}).reduce((formData, key) => {
		  const property = input[key];
		  formData.append(
			key,
			property instanceof Blob
			  ? property
			  : typeof property === "object" && property !== null
				? JSON.stringify(property)
				: `${property}`,
		  );
		  return formData;
		}, new FormData()),
	  [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
	};
  
	protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
	  return {
		...this.baseApiParams,
		...params1,
		...(params2 || {}),
		headers: {
		  ...(this.baseApiParams.headers || {}),
		  ...(params1.headers || {}),
		  ...((params2 && params2.headers) || {}),
		},
	  };
	}
  
	protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
	  if (this.abortControllers.has(cancelToken)) {
		const abortController = this.abortControllers.get(cancelToken);
		if (abortController) {
		  return abortController.signal;
		}
		return void 0;
	  }
  
	  const abortController = new AbortController();
	  this.abortControllers.set(cancelToken, abortController);
	  return abortController.signal;
	};
  
	public abortRequest = (cancelToken: CancelToken) => {
	  const abortController = this.abortControllers.get(cancelToken);
  
	  if (abortController) {
		abortController.abort();
		this.abortControllers.delete(cancelToken);
	  }
	};
  
	public request = async <T = any, E = any>({
	  body,
	  secure,
	  path,
	  type,
	  query,
	  format,
	  baseUrl,
	  cancelToken,
	  ...params
	}: FullRequestParams): Promise<HttpResponse<T, E>> => {
	  const secureParams =
		((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
		  this.securityWorker &&
		  (await this.securityWorker(this.securityData))) ||
		{};
	  const requestParams = this.mergeRequestParams(params, secureParams);
	  const queryString = query && this.toQueryString(query);
	  const payloadFormatter = this.contentFormatters[type || ContentType.Json];
	  const responseFormat = format || requestParams.format;
  
	  return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
		...requestParams,
		headers: {
		  ...(requestParams.headers || {}),
		  ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
		},
		signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
		body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
	  }).then(async (response) => {
		const r = response.clone() as HttpResponse<T, E>;
		r.data = null as unknown as T;
		r.error = null as unknown as E;
  
		const data = !responseFormat
		  ? r
		  : await response[responseFormat]()
			  .then((data) => {
				if (r.ok) {
				  r.data = data;
				} else {
				  r.error = data;
				}
				return r;
			  })
			  .catch((e) => {
				r.error = e;
				return r;
			  });
  
		if (cancelToken) {
		  this.abortControllers.delete(cancelToken);
		}
  
		if (!response.ok) throw data;
		return data;
	  });
	};
  }
  
  /**
   * @title MachineCertificateLambda
   * @version 1.0
   */
  export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	machinecertificate = {
	  /**
	   * No description
	   *
	   * @tags Credential
	   * @name GetMachineCredentials
	   * @request GET:/machinecertificate/credentials/{projectId}/machines
	   */
	  getMachineCredentials: (projectId: string, params: RequestParams = {}) =>
		this.request<MachineCredentialDto[], void>({
		  path: `/machinecertificate/credentials/${projectId}/machines`,
		  method: "GET",
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags Credential
	   * @name AddMachineCredential
	   * @request POST:/machinecertificate/credentials/{projectId}/machines
	   */
	  addMachineCredential: (
		projectId: string,
		query?: {
		  /** @format uuid */
		  machineId?: string;
		  machineName?: string;
		  /** @default false */
		  preSignedUrl?: boolean;
		},
		params: RequestParams = {},
	  ) =>
		this.request<MachineCredentialCertificateDto, ProblemDetails | void>({
		  path: `/machinecertificate/credentials/${projectId}/machines`,
		  method: "POST",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags Credential
	   * @name GetMachineCredential
	   * @request GET:/machinecertificate/credentials/{projectId}/machines/{machineId}
	   */
	  getMachineCredential: (projectId: string, machineId: string, params: RequestParams = {}) =>
		this.request<MachineCredentialDto, ProblemDetails | void>({
		  path: `/machinecertificate/credentials/${projectId}/machines/${machineId}`,
		  method: "GET",
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags Credential
	   * @name DeleteMachine
	   * @request DELETE:/machinecertificate/credentials/{projectId}/machines/{machineId}
	   */
	  deleteMachine: (projectId: string, machineId: string, params: RequestParams = {}) =>
		this.request<void, ProblemDetails | void>({
		  path: `/machinecertificate/credentials/${projectId}/machines/${machineId}`,
		  method: "DELETE",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags Credential
	   * @name RecreateCertificate
	   * @request POST:/machinecertificate/credentials/{projectId}/machines/{machineId}/recreate
	   */
	  recreateCertificate: (
		projectId: string,
		machineId: string,
		query?: {
		  machineName?: string;
		},
		params: RequestParams = {},
	  ) =>
		this.request<MachineCredentialCertificateDto, ProblemDetails | void>({
		  path: `/machinecertificate/credentials/${projectId}/machines/${machineId}/recreate`,
		  method: "POST",
		  query: query,
		  format: "json",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags Credential
	   * @name RevokeCredential
	   * @request PUT:/machinecertificate/credentials/{projectId}/machines/{machineId}/revoke
	   */
	  revokeCredential: (projectId: string, machineId: string, params: RequestParams = {}) =>
		this.request<void, ProblemDetails | void>({
		  path: `/machinecertificate/credentials/${projectId}/machines/${machineId}/revoke`,
		  method: "PUT",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags Credential
	   * @name MoveMachineToProject
	   * @request PUT:/machinecertificate/credentials/{projectId}/machines/{machineId}/move/{newProjectId}
	   */
	  moveMachineToProject: (projectId: string, machineId: string, newProjectId: string, params: RequestParams = {}) =>
		this.request<void, ProblemDetails | void>({
		  path: `/machinecertificate/credentials/${projectId}/machines/${machineId}/move/${newProjectId}`,
		  method: "PUT",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags Credential
	   * @name SetMachineCertificate
	   * @request PUT:/machinecertificate/credentials/{projectId}/machines/{machineId}/certificate
	   */
	  setMachineCertificate: (
		projectId: string,
		machineId: string,
		query?: {
		  enabled?: boolean;
		},
		params: RequestParams = {},
	  ) =>
		this.request<void, ProblemDetails | void>({
		  path: `/machinecertificate/credentials/${projectId}/machines/${machineId}/certificate`,
		  method: "PUT",
		  query: query,
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags Ping
	   * @name PingList
	   * @request GET:/machinecertificate/Ping
	   */
	  pingList: (params: RequestParams = {}) =>
		this.request<void, any>({
		  path: `/machinecertificate/Ping`,
		  method: "GET",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags Ping
	   * @name PingInfoDetail
	   * @request GET:/machinecertificate/Ping/info/{firstName}/{lastName}
	   */
	  pingInfoDetail: (firstName: string, lastName: string, params: RequestParams = {}) =>
		this.request<void, any>({
		  path: `/machinecertificate/Ping/info/${firstName}/${lastName}`,
		  method: "GET",
		  ...params,
		}),
  
	  /**
	   * No description
	   *
	   * @tags Ping
	   * @name PingCreate
	   * @request POST:/machinecertificate/Ping/{data}
	   */
	  pingCreate: (data: string, params: RequestParams = {}) =>
		this.request<void, any>({
		  path: `/machinecertificate/Ping/${data}`,
		  method: "POST",
		  ...params,
		}),
	};
  }