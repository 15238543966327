import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed, type Ref } from "vue";
import type {
	Api,
    AuditTrailsType
} from "../api-models";

export enum AuditTrailsPageType {
    Active = "Active",
    History = "History",
}

export function useAuditTrailsByMachine(
	api: Api<unknown>,
	machineId: Ref<string | undefined>,
) {
	return useQuery<AuditTrailsType[]>({
		queryKey: ["machineId", machineId.value],
		queryFn: async () => {
			const response = await api.auditTrails.getAuditTrailsByMachine({
                machineId: machineId.value!,
            });
			if (response.status !== 200) throw new Error(await response.text());
			return response.data;
		},
	});
}

export function useAuditTrailsActiveConnections(
    api: Api<unknown>,
) {
    return useQuery<AuditTrailsType[]>({
        queryKey: ["activeConnections"],
        queryFn: async () => {
            const response = await api.auditTrails.getAuditTrailsActiveAll();
            if (response.status !== 200) throw new Error(await response.text());
            return response.data;
        },
    });
}