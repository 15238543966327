<script setup lang="ts">
	import CertificateIcon from "@/icons/CertificateIcon.vue";
	import DataTable from "primevue/datatable";
	import type { DataTableFilterMetaData } from "primevue/datatable";
	import Column from "primevue/column";
	import Search from "@/components/Search.vue";
	
	import type { Project } from "../../composables/api-models";
	import {
		MachineCertificateStatus,
		type MachineCredentialDto,
		type Machine,
	} from "@/composables/machie-certificate-api";
	import { ref } from "vue";
	import Loading from "@/components/Loading.vue";
	
	defineProps<{
		machines: Machine[];
		project: Project;
		loading: boolean;
	}>();

	const emit = defineEmits<{
		add: [];
		revoke: [machine: Machine];
		recreate: [machine: Machine];
		create: [machine: Machine];
		downloadCertificate: [item: MachineCredentialDto];
		moveMachine: [machine: Machine];
		setMachineCertificateStatus: [machine: Machine];
		viewHistroy: [machine: Machine];
	}>();

	// this holds the filter values from primevue table
	const filters = ref<{ global: DataTableFilterMetaData }>({
		global: {
			value: null,
			matchMode: "contains",
		},
	});
</script>

<template>
	<div class="box-border flex min-h-0 flex-col gap-4 p-4">
		<div
			class="customizedPrimeVueTable flex grid grid-cols-4 flex-col gap-2 gap-4 overflow-y-auto"
		>
		
			<DataTable
				tableStyle="min-width: 100%"
				paginator
				:rows="5"
				:filters="filters"
				:value="machines"
				:loading="loading"
				:globalFilterFields="['MachineId', 'MachineName']"
				class="col-span-4"
			>
				<template #header>
					<div class="mb-5 flex justify-between">
						<Search :filters-prop="filters" />
						<button
							class="mb-2 mr-2 flex items-center rounded bg-bright-orange px-4 py-2 font-bold text-dark-orange md:mb-0"
							@click="emit('add')"
						>
							<span> Create New Machine </span>
						</button>
					</div>
				</template>
				<!-- Columns -->
				<Column
					field="MachineName"
					header="Machine Name"
					sortable
				/>

				<Column
					field="MachineId"
					header="Machine ID"
					sortable
				/>

				<Column
					field="CertificateStatus"
					header="Status"
					sortable
				>
					<template #body="{ data }">
						{{ MachineCertificateStatus[data.CertificateStatus] }}
					</template>
				</Column>

				<Column
					field="CertificateStatus"
					header="Actions"
				>
					<template #body="{ data }">
						<div class="flex h-24 w-full items-center justify-center">
							<button
								v-if="
									data.CertificateStatus === MachineCertificateStatus.Active
								"
								:title="'Download'"
								class="button icon-button button-secondary"
								@click="emit('downloadCertificate', data)"
							>
								<CertificateIcon />
							</button>

							<button
								:title="'Move Machine'"
								class="button icon-button button-secondary"
								@click="emit('moveMachine', data)"
							>
							<i class="material-icons">edit</i>
								
							</button>

							<button
								:title="'Set Machine Certificate Status'"
								class="button icon-button button-secondary"
								@click="emit('setMachineCertificateStatus', data)"
							>
							<i class="material-icons">toggle_on</i>
								
							</button>

							<button
								:title="'View History'"
								class="button icon-button button-secondary"
								@click="emit('viewHistroy', data)">
								<i class="material-icons">history</i>
							</button>

							
					
						</div>
					</template>
				</Column>
				<template #empty>No machine found.</template>
				<template #loading>
					<Loading typeOfData="Machines" />
				</template>
			</DataTable>
		</div>
	</div>
</template>
