<script setup lang="ts">
	import ClockSpinnerIcon from "@/icons/ClockSpinnerIcon.vue";
	import { DialogLayout } from "@jca/libs/ui";
	import {
		computed,
		reactive,
		ref,
		watchEffect,
	} from "vue";
	import Dropdown from "@/components/Dropdown.vue";
	import { Option } from "@/components/Dropdown.vue";
	import { Project } from "../../composables/api-models"; // Import the Option type
	import { useHttp, useProjectList } from "@/composables/umc-api";
	import { emptyGUID } from "@jca/libs/api";
	import { z } from "zod";
	import { useValidation } from "@jca/libs/forms";

	const props = defineProps<{
		loading: boolean;
		error?: string | null;
		projectId: string;
	}>();

	const emit = defineEmits<{
		close: [{ newProjectId: string }?];
	}>();

	const machineSchema = z.object({
		newProjectId: z.string().min(1, { message: "Project is required" }),
	});

	const result = reactive({
		newProjectId: "",
	});

	const validation = useValidation(result, machineSchema);

	async function save() {
		if (validation.validate()) {
			emit("close", result);
		}
	}

	const http = useHttp();
	const { isLoading: isProjectLoading, data: projects } = useProjectList(http);
	const isLoading = computed(() => {
		return isProjectLoading.value;
	});
	// Track the state of the dropdown and search box
	const isDropdownOpen = ref(false);
	const isTyping = ref(false);
	// Track the state of the dropdown (whether it is open or not)
	// Function to toggle dropdown open/close state
	const toggleDropdown = () => {
		isDropdownOpen.value = !isDropdownOpen.value;
	};

	// Function to handle typing in the search box
	const handleSearchTyping = () => {
		isTyping.value = true;
	};

	// we don't want to show the empty project for admin
	const filterProjects = (projects: Project[]) => {
		return projects.filter(
			project =>
				project.ProjectId !== emptyGUID && project.ProjectId != props.projectId,
		);
	};

	const handleSelection = (selectedProject: Option) => {
		result.newProjectId = selectedProject.ProjectId;
	};
	// Function to convert projects to the Option[] type
	function convertToOption(project: Project): Option {
		return {
			id: project.ProjectId ?? "", // Assign a default value if project.ProjectId is undefined
			label: project.Name ?? "",
			...project,
		};
	}
	const options = ref<Option[]>([]); // Array of options to be populated after fetching
	watchEffect(() => {
		if (projects.value) {
			options.value = filterProjects(projects.value).map(convertToOption);
		}
	});
</script>

<template>
	<DialogLayout
		:class="isDropdownOpen || isTyping ? 'h-[18rem]' : ''"
		class="w-[20rem] bg-netural-orange"
	>
		<template #title>
			<span class="font-bold text-dark-green">Edit Machine</span>
		</template>

		<div class="mx-auto w-full max-w-sm">
			<Dropdown
				:options="options"
				placeholder="Select a project"
				valueField="ProjectId"
				displayField="Name"
				@click="toggleDropdown"
				@search="handleSearchTyping"
				@update:modelValue="handleSelection"
			/>
			<div
				v-if="validation.errors.newProjectId"
				class="error-text absolute top-[6.5rem] ml-4"
			>
				{{ validation.errors.newProjectId }}
			</div>
		</div>

		<template #actions>
			<button
				class="button bg-light-orange"
				:disabled="props.loading"
				@click="emit('close')"
			>
				Cancel
			</button>
			<button
				class="button bg-bright-orange"
				:disabled="props.loading"
				@click="save"
			>
				<ClockSpinnerIcon v-if="loading" />
				<span>Save</span>
			</button>
		</template>
	</DialogLayout>
</template>
